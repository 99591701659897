import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import connectionsReducer from './Connections/reducer';
import timelineReducer from './Timelines/reducer';
import invitationsReducer from './Invitations/reducer';
import magicLinksReducer from './MagicLinks/reducer';
import searchesReducer from './Search/reducer';
import locationReducer from './Location/reducer';
import updateReducer from './Update/reducer';
import agentsReducer from './AgentDetails/reducer';
import dealReducer from './Deal/reducer';
import cashOffersReducer from './CashOffers/reducer';
import announcementsReducer from './Announcements/reducer';
import userReducer from './User/reducer';
import commissionModelReducer from './CommissionModel/reducer';

export const rootReducer = combineReducers({
  connections: connectionsReducer,
  timeline: timelineReducer,
  invitations: invitationsReducer,
  magicLinks: magicLinksReducer,
  searches: searchesReducer,
  location: locationReducer,
  agents: agentsReducer,
  update: updateReducer,
  deal: dealReducer,
  cashOffers: cashOffersReducer,
  announcements: announcementsReducer,
  user: userReducer,
  commissionModel: commissionModelReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type State = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export default store;
