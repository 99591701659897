import { Auth } from '@aws-amplify/auth';

const cookieStorage = {
  domain:
    process.env.NODE_ENV === 'development' ? 'localhost' : 'listwithclever.com',
  secure: true,
  path: '/',
  expires: 365, // expires in days
  sameSite: 'none',
};

Auth.configure({
  region: process.env.AWS_AMPLIFY_REGION,
  userPoolId: process.env.AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  cookieStorage,
});
