import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import instance from '../../AxiosInstance';

// //
// Async Thunk Actions
// //

export const getInvitation = createAsyncThunk<Invitation, string | number>(
  'invitations/getInvitation',
  async (inviteId, { rejectWithValue }) => {
    try {
      const response = await instance.get(`/deals/invitation/${inviteId}/`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const typedError: any = error;
      return rejectWithValue(typedError.response.data);
    }
  },
);

export const acceptInvitation = createAsyncThunk<Invitation, string | number>(
  'invitations/acceptInvitation',
  async (inviteId, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/deals/invitation/${inviteId}/accept/`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const typedError: any = error;
      return rejectWithValue(typedError.response.data);
    }
  },
);

export const rejectInvitation = createAsyncThunk<Invitation, string | number>(
  'invitations/rejectInvitation',
  async (inviteId, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/deals/invitation/${inviteId}/reject/`,
      );
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      const typedError: any = err;
      return rejectWithValue(typedError.response.data);
    }
  },
);

/**
 * Used to complete our Educational C2C onboarding.
 */
export const getSendClaimedComms = createAsyncThunk<any, string | number>(
  'invitations/getSendClaimedComms',
  async (inviteId, { rejectWithValue }) => {
    try {
      const response = await instance.post(
        `/deals/invitation/${inviteId}/send-claimed-comms/`,
        { agent_is_educated: true },
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const typedError: any = error;
      return rejectWithValue(typedError.response.data);
    }
  },
);
