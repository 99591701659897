/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { sendToast } from '../../utils';
import { deleteCashOffer, patchCashOffer, saveCashOffer } from './actions';

const initialState = {
  hasErrors: false,
  loading: false,
  success: false,
  successfullyDeleted: false,
  successfullyPatched: false,
};

const cashOffersSlice = createSlice({
  name: 'cashOffers',
  initialState,
  reducers: {
    resetState: (state) => {
      state.hasErrors = false;
      state.loading = false;
      state.success = false;
      state.successfullyDeleted = false;
      state.successfullyPatched = false;
    },
  },
  extraReducers: (builder) => {
    // cashOffer save
    builder.addCase(saveCashOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveCashOffer.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        sendToast(
          payload.message
            || 'There was an error saving your cash offer. Please try again or contact a Clever support member.',
          { type: 'error' },
        );
      } else {
        state.hasErrors = false;
        state.success = true;
      }
    });
    builder.addCase(saveCashOffer.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
      sendToast(
        'There was an error saving your cash offer. Please try again or contact a Clever support member.',
        { type: 'error' },
      );
    });
    // Cash Offer Delete
    builder.addCase(deleteCashOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCashOffer.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        sendToast(
          payload.message
            || 'There was an error removing your cash offer. Please try again or contact a Clever support member.',
          { type: 'error' },
        );
      } else {
        state.hasErrors = false;
        state.successfullyDeleted = true;
      }
    });
    builder.addCase(deleteCashOffer.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
      sendToast(
        'There was an error removing your cash offer. Please try again or contact a Clever support member.',
        { type: 'error' },
      );
    });
    // Cash Offer PATCH
    builder.addCase(patchCashOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchCashOffer.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.error) {
        state.hasErrors = true;
        sendToast(
          payload.message
            || 'There was an error removing your cash offer. Please try again or contact a Clever support member.',
          { type: 'error' },
        );
      } else {
        state.hasErrors = false;
        state.successfullyPatched = true;
      }
    });
    builder.addCase(patchCashOffer.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
      sendToast(
        'There was an error removing your cash offer. Please try again or contact a Clever support member.',
        { type: 'error' },
      );
    });
  },
});

export const { resetState } = cashOffersSlice.actions;

// Reducer
export default cashOffersSlice.reducer;
