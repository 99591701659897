const getCommissionBannerName = (commissionModelName) => {
  switch (commissionModelName) {
    case 'Flat Fee Model':
      return 'Listing Commission: $3,000';
    case '1% Model':
      return 'Listing Commission: 1%';
    case '<125k Model':
    case '<75k Model':
      return 'Listing Commission: Traditional';
    case 'Buyer Tiered RebateModel':
    case 'Buyer No Clever Cash Back':
    case 'Buyer $1K Rebate Model':
    case 'Buyer 1% Rebate Model':
      return 'Clever Cash Back: None';
    case 'Clever Cash Back ($250)':
      return 'Clever Cash Back: $250';
    case 'Clever Cash Back (0.5%)':
      return 'Clever Cash Back: 0.5%';
    case 'Buyer Model (new $250)':
      return 'Clever Cash Back: $250';
    case '1.5% Model':
      return 'Listing Commission: 1.5% *NEW*';
    default:
      return '';
  }
};

export default getCommissionBannerName;
