import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from '../../AxiosInstance';

export const fetchSelf = createAsyncThunk<User>('user/fetchSelf', async () => {
  try {
    // check if the object exists in local storage
    if (localStorage.getItem('me')) {
      const parsed = JSON.parse(localStorage.getItem('me') || '');
      if (parsed) {
        return parsed;
      }
    }
    // if no local storage for /me then fetch it
    const response = await Axios.get('/contacts/me/');
    localStorage.setItem('me', JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    return { error };
  }
});
