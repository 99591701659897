/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchAnnouncements } from './actions';

const initialState = {
  announcements: [] as Array<Announcement>,
  error: false as boolean,
  loading: false as boolean,
};

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAnnouncements.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAnnouncements.fulfilled, (state, { payload }) => {
      state.announcements = payload.objects;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(fetchAnnouncements.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});

export default announcementsSlice.reducer;
