import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import instance from '../../AxiosInstance';

// //
// Async Thunk Actions
// //

export interface ConnectionCounts {
  'on-hold': number;
  active: number;
  payments: number;
  closed: number;
  released: number;
  'payments-processed': number;
  'active-updated': number;
  unclaimed: number;
  inactive: number;
  claimed: number;
}

export const ConnectionTypes = {
  onHold: 'on-hold',
  active: 'active',
  payments: 'payments',
  closed: 'closed',
  released: 'released',
  paymentsProcessed: 'payments-processed',
  activeUpdated: 'active-updated',
};

export interface PaginatedNextOptions {
  type: string; // Connection Types
  nextUrl: string; // next URL property
}

export interface PaginatedConnections {
  count: number;
  next: string | null;
  previous: string | null;
  results: Connection[];
}

export interface PaginatedInvitations {
  count: number;
  next: string | null;
  previous: string | null;
  results: Invitation[];
}

export interface PaginatedResponse {
  data: PaginatedConnections | PaginatedInvitations;
  type: string;
}

// Pagination
export const getNextConnections = createAsyncThunk<
  PaginatedResponse,
  PaginatedNextOptions
>('connections/requestNextConnections', async (props) => {
  try {
    const response = await instance.get(props.nextUrl);
    return { data: response.data, type: props.type };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

export const getConnectionsFromPageCount = createAsyncThunk<
  PaginatedResponse,
  PaginatedNextOptions
>('connections/getConnectionsFromPageCount', async (props) => {
  try {
    const response = await instance.get(props.nextUrl);
    return { data: response.data, type: props.type };
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// Payment Connections
export const requestPaymentConnections = createAsyncThunk<
  PaginatedConnections,
  string | number
>('connections/requestPaymentConnections', async (agentId) => {
  try {
    const response = await instance.get(
      `/deals/agents/${agentId}/connections/payments/`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// Payments Released Connections
export const requestPaymentsProcessedConnections = createAsyncThunk<
  PaginatedConnections,
  string | number
>('connections/requestPaymentsProcessedConnections', async (agentId) => {
  try {
    const response = await instance.get(
      `/deals/agents/${agentId}/connections/payments-processed/`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// On Hold Connections
export const requestOnHoldConnections = createAsyncThunk<
  PaginatedConnections,
  string | number
>('connections/requestOnHoldConnections', async (agentId) => {
  try {
    const response = await instance.get(
      `/deals/agents/${agentId}/connections/on-hold/`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// Active Connections
export const requestActiveConnections = createAsyncThunk<
  PaginatedConnections,
  string | number
>('connections/requestActiveConnections', async (agentId) => {
  try {
    const response = await instance.get(
      `/deals/agents/${agentId}/connections/active/`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// Recently Updated Active Connections
export const requestRecentlyUpdatedConnections = createAsyncThunk<
  PaginatedConnections,
  string | number
>('connections/requestRecentlyUpdatedConnections', async (agentId) => {
  try {
    const response = await instance.get(
      `/deals/agents/${agentId}/connections/active-updated/`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// Released Connections
export const requestReleasedConnections = createAsyncThunk<
  PaginatedConnections,
  string | number
>('connections/requestReleasedConnections', async (agentId) => {
  try {
    const response = await instance.get(
      `/deals/agents/${agentId}/connections/released/`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// Closed Connections
export const requestClosedConnections = createAsyncThunk<
  PaginatedConnections,
  string | number
>('connections/requestClosedConnections', async (agentId) => {
  try {
    const response = await instance.get(
      `/deals/agents/${agentId}/connections/closed/`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// Single Connections
export const requestSingleConnection = createAsyncThunk<
  Connection,
  string | number
>('connections/requestSingleConnection', async (connectionId) => {
  try {
    const response = await instance.get(
      `/deals/connections/${connectionId}/?expanded=true`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// Connection Counts
export const getConnectionsCounts = createAsyncThunk<
  ConnectionCounts,
  string | number
>('connections/getConnectionsCount', async (agentId) => {
  try {
    const response = await instance.get(
      `/deals/agents/${agentId}/connections/`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

export interface Filter {
  agentId: string | number;
  filter: string;
  type?: string;
}

// Filter counts
export const filterCounts = createAsyncThunk<ConnectionCounts, Filter>(
  'connections/getConnectionsCountFromFilter',
  async (filter) => {
    try {
      const response = await instance.get(
        `/deals/agents/${filter.agentId}/connections/?filter=${filter.filter}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
);

// Filter Connections
export const filterConnections = createAsyncThunk<PaginatedConnections, Filter>(
  'connections/filterConnections',
  async (filter) => {
    try {
      const response = await instance.get(
        `/deals/agents/${filter.agentId}/connections/${filter.type}/?filter=${filter.filter}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
);

export interface FetchC2CProps {
  agentId: string;
  type: string;
}

export const fetchAllC2Cs = createAsyncThunk<
  PaginatedInvitations,
  FetchC2CProps
>('connections/fetchAllC2Cs', async (props) => {
  try {
    const response = await instance.get(
      `/deals/agents/${props.agentId}/invitations/${props.type}/`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});
