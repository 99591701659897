/**
 * Get login url
 *
 * @returns {string} Clever login gateway url
 */
const getLoginUrl = (): string => {
  const env = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

  if (env === 'production') {
    return 'https://login.listwithclever.com/';
  }

  if (env === 'staging') {
    return 'https://login-staging.listwithclever.com/';
  }

  return 'http://localhost:3001/';
};

export default getLoginUrl;
