const clearFSEventFire = (callback, attempts = 0) => {
  const limit = 3;
  const waitMs = 3000;

  const { FS }: any = window;
  if (typeof FS === 'undefined' && attempts < limit) {
    setTimeout(() => clearFSEventFire(callback, attempts + 1), waitMs);
  } else if (typeof FS !== 'undefined') {
    callback(FS);
  }
};

export default clearFSEventFire;
