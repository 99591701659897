import * as Sentry from '@sentry/gatsby';
import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../AxiosInstance';

export const fetchAgentById = createAsyncThunk<Agent, string | number>(
  'agents/fetchAgentById',
  async (agentId) => {
    try {
      const response = await instance.get(`/deals/agent/${agentId}/`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
);
