module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Clever agent","short_name":"Clever","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display_override":["fullscreen","minimal-ui"],"display":"standalone","icon":"src/images/icon.svg","icons":[{"src":"src/images/favicons/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"src/images/favicons/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/images/favicons/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/favicons/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/favicons/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicons/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"src/images/favicons/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"src/images/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b7b1d46b3d2a242b4d0b2f01051b3849"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
