import { getDaysBetween } from '../getDaysBetween';

const getAlertClass = (date: string | Date | null): ThemeColors => {
  if (!date) {
    return 'gray';
  }
  const daysBetween = getDaysBetween(date);
  return daysBetween >= 5 && daysBetween <= 6 ? 'warning' : 'error';
};

export default getAlertClass;
