import React from 'react';
import * as Sentry from '@sentry/gatsby';
import 'typeface-inter';
import 'typeface-rubik';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from '../../store';
import '../../config/Cognito';

const ReduxWrapper = ({ element }) => (
  <Sentry.ErrorBoundary showDialog>
    <Provider store={store}>
      {element}
      <ToastContainer />
    </Provider>
  </Sentry.ErrorBoundary>
);

export default Sentry.withProfiler(ReduxWrapper);
