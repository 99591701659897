const formatCustomerName = (customer) => {
  let result = '';
  if (customer && customer.firstname) {
    result += customer.firstname;
  }
  if (customer && customer.firstname && customer.lastname) {
    result += ` ${customer.lastname}`;
  }
  return result;
};

export default formatCustomerName;
