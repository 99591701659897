import { C2CTypes } from '../../Enums/C2CTypes';

const constructConnectionPageUrl = (page, agentId, type) => {
  if (
    type === C2CTypes.claimed
    || type === C2CTypes.inactive
    || type === C2CTypes.unclaimed
  ) {
    return `/deals/agents/${agentId}/invitations/${type}/?page=${page}`;
  }
  const paramString = `?page=${page}`;
  return `/deals/agents/${agentId}/connections/${type}/${paramString}`;
};

export default constructConnectionPageUrl;
