/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';
import { fetchSearchById } from './actions';

const initialState = {
  hasErrors: false,
  loading: false,
  search: null as Search | null,
  error: null as SerializedError | null,
};

const searchesSlice = createSlice({
  name: 'searches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSearchById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchById.fulfilled, (state, { payload }) => {
      state.search = payload;
      state.loading = false;
      state.hasErrors = false;
    });
    builder.addCase(fetchSearchById.rejected, (state, { error }) => {
      state.hasErrors = true;
      state.loading = false;
      state.error = error;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching the deal information. Please try again or contact a Clever support member.',
        },
      });
    });
  },
});

export default searchesSlice.reducer;
