import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://5b06e6b2da29454f8e9ec824893a9f84@o385035.ingest.sentry.io/5217211',
  sampleRate: 0.7,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
      networkDetailAllowUrls: ['https://api.listwithclever.com/'],
      networkRequestHeaders: ['X-Custom-Header', 'Cache-Control'],
      networkResponseHeaders: ['X-Custom-Header', 'Referrer-Policy'],
    }),
  ],
  environment: 'production',
  // environment: process.env.NODE_ENV,
  enabled: true,
  // enabled: process.env.NODE_ENV === 'production',
});
