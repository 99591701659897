import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import instance from '../../AxiosInstance';

export type FetchModelParams = {
  id: number;
};

export const fetchModel = createAsyncThunk<CommissionModel, FetchModelParams>(
  'commissionModel/fetchModel',
  async (params, { rejectWithValue }) => {
    try {
      const { id } = params;

      if (!id) {
        throw new Error(
          'Cannot proceed without an id for the Commission Model.',
        );
      }

      const response = await instance.get(`/deals/commission-model/${id}`);

      return response.data;
    } catch (error: any) {
      Sentry.captureException(error);
      const val = error.message || error;
      return rejectWithValue(val);
    }
  },
);
