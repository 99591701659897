import * as Sentry from '@sentry/gatsby';
import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../AxiosInstance';

export const postUpdate = createAsyncThunk<any, any>(
  'update/postUpdate',
  async (data, { getState }: any) => {
    const connectionId = getState().connections.singleConnection.id;

    try {
      const response = await instance.post(
        `/connection/${connectionId}/update/`,
        data,
      );
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      const typedError: any = err;
      if (typedError.response.status === 500) {
        return {
          error:
            'There was an error updating your connection. Please try again.',
        };
      }
      if (typedError.response.message) {
        return {
          error: typedError.response.message,
        };
      }
      // TODO: Error handling
      return {
        error: 'There was an error updating your connection. Please try again',
      };
    }
  },
);
