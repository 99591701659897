/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getLocationData, LocationObject } from './actions';

const initialState = {
  loading: false,
  location: null as LocationObject | boolean | null,
  hasErrors: false,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocationData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLocationData.fulfilled, (state, { payload }) => {
      state.hasErrors = false;
      state.loading = false;
      state.location = payload;
    });
    builder.addCase(getLocationData.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
    });
  },
});

export default locationSlice.reducer;
