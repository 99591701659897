import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../AxiosInstance';

export const saveCashOffer = createAsyncThunk<
  CashOffers | any,
  CashOfferPostProps
>('cashOffers/saveCashOffer', async (cashOfferProps) => {
  try {
    const response = await instance.post('/deals/cash-offer/', {
      ...cashOfferProps,
    });
    return response.data;
  } catch (e) {
    const typedErr: any = e;
    let message = 'There was an error saving the cash offer. Please try again';
    if (typedErr.response.data && typedErr.response.data.message) {
      // eslint-disable-next-line prefer-destructuring
      message = typedErr.response.data.message;
    }
    return {
      error: true,
      message,
    };
  }
});

export const deleteCashOffer = createAsyncThunk<any, string>(
  'cashOffers/deleteCashOffer',
  async (cashOfferId) => {
    try {
      const response = await instance.delete(
        `/deals/cash-offer/${cashOfferId}/`,
      );
      return response.data;
    } catch (e) {
      const typedErr: any = e;
      let message = 'There was an error deleting the cash offer. Please try again';
      if (typedErr.response.data && typedErr.response.data.message) {
        // eslint-disable-next-line prefer-destructuring
        message = typedErr.response.data.message;
      }
      return {
        error: true,
        message,
      };
    }
  },
);

// TODO: patch cash offer
export const patchCashOffer = createAsyncThunk<
  CashOffers | any,
  CashOfferPostProps
>('cashOffers/patchCashOffer', async (cashOfferProps) => {
  try {
    const { id } = cashOfferProps;
    const requestProps = {
      company_name: cashOfferProps.company_name,
      is_winning_cash_offer: cashOfferProps.is_winning_cash_offer || false,
      amount: cashOfferProps.amount,
    };
    const response = await instance.patch(`/deals/cash-offer/${id}/`, {
      ...requestProps,
    });
    return response.data;
  } catch (e) {
    const typedErr: any = e;
    let message = 'There was an error saving the cash offer. Please try again';
    if (typedErr.response.data && typedErr.response.data.message) {
      // eslint-disable-next-line prefer-destructuring
      message = typedErr.response.data.message;
    }
    return {
      error: true,
      message,
    };
  }
});
