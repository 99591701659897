import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import instance from '../../AxiosInstance';

export interface LocationObject {
  latitude: number | string | null;
  longitude: number | string | null;
  address: string | null;
  street_number: string | number | null;
  city: string | number | null;
  region: string | number | null;
  state: string | number | null;
  country: string | number | null;
  zipcode: string | number | null;
}

export const getLocationData = createAsyncThunk<
  LocationObject | boolean,
  string
>('location/getLocationData', async (address) => {
  try {
    const response = await instance.get(
      `/locations/search/?q=${encodeURIComponent(address)}`,
    );
    return response.data.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});
