import { format, utcToZonedTime } from 'date-fns-tz';

const formatDateToTimeZone = (formatDate) => {
  const utcDate = new Date(formatDate);
  if (!Number.isNaN(utcDate.getTime())) {
    // eslint-disable-next-line prefer-destructuring
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = utcToZonedTime(utcDate, timeZone);
    return format(zonedDate, "MMM do 'at' p z", { timeZone });
  }
  return null;
};

export default formatDateToTimeZone;
