import * as Sentry from '@sentry/gatsby';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertDateStringToFormat } from '../../utils';
import instance from '../../AxiosInstance';

export const requestTimeline = createAsyncThunk<
  TimelineCell[],
  string | number
>('timeline/requestTimeline', async (connectionId) => {
  try {
    const response = await instance.get(
      `/deals/connections/${connectionId}/updates/`,
    );

    const newArrayOfTimelineObjects = response.data;
    newArrayOfTimelineObjects.sort(
      (a, b) => new Date(a.date).getDate() - new Date(b.date).getDate(),
    );
    const timelineCells: TimelineCell[] = newArrayOfTimelineObjects.map(
      (item) => ({
        dateString: convertDateStringToFormat(item.created_at),
        text: item.note,
        callouts: [
          {
            type: 'completed',
            text: item.status,
          },
        ],
      }),
    );
    return timelineCells;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});
