/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { requestTimeline } from './actions';

const initialState = {
  loading: false,
  timelineCells: [] as TimelineCell[],
  hasErrors: false,
};

const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestTimeline.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestTimeline.fulfilled, (state, { payload }) => {
      state.hasErrors = false;
      state.timelineCells = payload;
    });
    builder.addCase(requestTimeline.rejected, (state) => {
      state.hasErrors = true;
    });
  },
});

export default timelineSlice.reducer;
