import { toast } from 'react-toastify';

const sendToast = (text: string, args: object) => {
  const defaultArgs = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };
  toast(text, { ...defaultArgs, ...args } as any);
};

export default sendToast;
