export enum C2CTypes {
  unclaimed = 'unclaimed',
  claimed = 'claimed',
  inactive = 'inactive',
}

/**
 * The different types of C2C programs. Will dicatate the flow
 * of C2C screens the user sees.
 */
export enum C2CProgram {
  pro_deals_program = 'pro_deals_program',
  offers_deals_program = 'offers_deals_program',
}
