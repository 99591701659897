/* eslint-disable max-depth */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage,
} from 'amazon-cognito-identity-js';
import { Auth, CognitoUser } from '@aws-amplify/auth';

// //
// Async Thunk Actions
// //

const baseURL = process.env.GATSBY_API_URL || 'http://localhost:8000';

const setTokensAndAuthenticate = async (accessToken, idToken, refreshToken) => {
  // Fetch the current config
  const config = Auth.configure();
  // Set the user pool
  const userPool = new CognitoUserPool({
    UserPoolId: config.userPoolId as any,
    ClientId: config.userPoolWebClientId as any,
    Storage: new CookieStorage(config.cookieStorage as any),
  });
  // Set the id token
  const cognitoIdToken = new CognitoIdToken({
    IdToken: idToken,
  });
  // set the access token
  const cognitoAccessToken = new CognitoAccessToken({
    AccessToken: accessToken,
  });
  // set the refresh token
  const cognitoRefreshToken = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  });
  // get the "username" from the tokens
  const username = cognitoIdToken.payload.email;
  // set the cognito user
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
    Storage: new CookieStorage(config.cookieStorage as any),
  });
  // then sign in the user with the generated user session
  user.setSignInUserSession(
    new CognitoUserSession({
      AccessToken: cognitoAccessToken,
      IdToken: cognitoIdToken,
      RefreshToken: cognitoRefreshToken,
    }),
  );
};

export const getTokensFromMagicLink = createAsyncThunk<
  MagicLink,
  string | number
>(
  'magicLinks/getTokensFromMagicLink',
  async (magicUuid, { rejectWithValue }) => {
    try {
      // TODO: use old magic link endpoint later
      const response = await axios.get(
        `${baseURL}/contacts/magic-link/${magicUuid}/`,
      );
      const { access, refresh, id_token } = response.data;

      await setTokensAndAuthenticate(access, id_token, refresh);

      const session = await Auth.currentSession();
      if (session) {
        const accessTokenExpire = session.getAccessToken().getExpiration();
        const refreshToken = session.getRefreshToken();
        const currentTimeSeconds = Math.round(+new Date() / 1000);
        // if token has expired refresh it and set authed to true
        if (accessTokenExpire < currentTimeSeconds) {
          const authenticatedUser = await Auth.currentAuthenticatedUser();
          if (authenticatedUser) {
            await authenticatedUser.refreshSession(refreshToken);
          }
        }
      }
      // Testing link
      return response.data;
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.status);
      }
      if (e.request) {
        return rejectWithValue('No Response');
      }
      return rejectWithValue(e.message);
    }
  },
);

export const createNewMagicLink = createAsyncThunk<
  MagicLinkRequestResponse,
  MagicLinkRequestBody
>('magicLinks/createNewMagicLink', async (body, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${baseURL}/contacts/magic-link/`, body);
    return response.data;
  } catch (e: any) {
    if (e.response.status === 500) {
      return rejectWithValue(
        'Something went wrong while creating a new magic link.',
      );
    }
    if (e.response) {
      return rejectWithValue(e.response.data);
    }
    if (e.request) {
      return rejectWithValue('No Response');
    }
    return rejectWithValue('Something went wrong while creating magic link');
  }
});
