/* eslint-disable import/no-extraneous-dependencies */
import * as Sentry from '@sentry/gatsby';

// FIXME: re-add with Clarity. See https://listwithclever.atlassian.net/browse/TD-51.

export enum EduC2CEvent {
  Invited = 'Invited',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  CancelledReject = 'CancelledReject',
  EduClaimed = 'EduClaimed',
  EduMortgagePartner = 'EduMortgagePartner',
  EduAgentExpectations = 'EduAgentExpectations',
  EduSuccess = 'EduSuccess',
  EduCallCTA = 'EduCallCTA',
}

export enum EduC2CType {
  Pro = 'Pro',
  Offer = 'Offer',
}

const handleEduC2CSmartLookError = (error: any) => {
  Sentry.captureException(error);
  const message = error.message || error;
  console.error(message);
};

export const sendEduC2CSmartLook = (
  event: EduC2CEvent,
  isProDeal?: boolean,
  isOffersDeal?: boolean,
) => {
  try {
    if (!event) {
      throw new Error('Cannot measure without an event.');
    }

    let type = 'Unknown';
    if (isProDeal) {
      type = EduC2CType.Pro;
    } else if (isOffersDeal) {
      type = EduC2CType.Offer;
    }

    const eventName = `EduC2C_${event.toString()}_${type.toString()}`;

    console.debug('Event Name', eventName);

    return true;
    // return Smartlook.track(eventName, {});
  } catch (error: any) {
    handleEduC2CSmartLookError(error);
    return false;
  }
};
