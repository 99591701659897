/**
 * Convert date string to format
 *
 * @param date Date string
 * @returns Formatted date string
 */
const convertDateStringToFormat = (date: string | Date | null): string => {
  if (!date) {
    return '';
  }
  const dtf = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
  const d = new Date(date);
  const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d);

  return `${mo} ${da} ${ye}`;
};

export default convertDateStringToFormat;
