const isCashbackEligible = (state) => {
  const nonElibibleStates = [
    'IA',
    'MO',
    'KS',
    'OK',
    'TN',
    'MS',
    'LA',
    'OR',
    'AK',
  ];
  return !(nonElibibleStates.indexOf(state) > -1);
};

export default isCashbackEligible;
