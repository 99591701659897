/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { navigate } from 'gatsby-link';
import {
  acceptInvitation,
  getInvitation,
  getSendClaimedComms,
  rejectInvitation,
} from './actions';

export const errorMessages = {
  AcceptInviteInvalid:
    'Sorry, this connection has already been claimed by another agent. Please feel free to contact a Clever representative if you have any questions.',
  AcceptInviteClaimedMoreThanOnce:
    'Cannot claim a Click-to-Claim Invitation more than once. Please feel free to contact a Clever representative.',
  AcceptInviteSameBrokerage:
    'Cannot claim a Click-to-Claim Invitation by the same brokerage. Please feel free to contact a Clever representative.',
  AcceptInviteSearchNotOpen:
    'Attempt to claim Click-to-Claim but search is not open. The current agent search is still ongoing. Please feel free to contact a Clever representative.',
  AcceptInviteDefault:
    'There was an unknown error that occured. Please contact Clever support team.',
  AcceptInviteInvalidPhone:
    'You do not have a valid phone number or email in our system. Please contact Clever support team.',
  AcceptInviteCustomerInvalid:
    'Customer does not have a valid phone number or email in our system. Please contact Clever support team.',
  RejectInviteInvalid:
    'Attempted to reject an invalid Click-to-Claim Invitation. Please feel free to contact a Clever representative.',
  RejectInviteClaimedMoreThanOnce:
    'Cannot reject a Click-to-Claim Invitation more than once. Please feel free to contact a Clever representative.',
  RejectInviteSearchNotOpen:
    'Attempt to reject Click-to-Claim Search but the search is not open. The current agent search is still ongoing. Please feel free to contact a Clever representative.',
  RejectInviteDefault:
    'There was an unkown error that occured. Please contact Clever support team.',
};

export const handleAcceptErrorMessaging = (code: number) => {
  switch (code) {
    case 1:
      return errorMessages.AcceptInviteInvalid;
    case 2:
      return errorMessages.AcceptInviteClaimedMoreThanOnce;
    case 3:
      return errorMessages.AcceptInviteSameBrokerage;
    case 4:
      return errorMessages.AcceptInviteSearchNotOpen;
    case 5:
      return errorMessages.AcceptInviteInvalidPhone;
    case 6:
      return errorMessages.AcceptInviteCustomerInvalid;
    default:
      return errorMessages.AcceptInviteDefault;
  }
};

export const handleRejectErrorMessaging = (code: number) => {
  switch (code) {
    case 1:
      return errorMessages.RejectInviteInvalid;
    case 2:
      return errorMessages.RejectInviteClaimedMoreThanOnce;
    case 4:
      return errorMessages.RejectInviteSearchNotOpen;
    default:
      return errorMessages.RejectInviteDefault;
  }
};

const initialState = {
  loading: false,
  invitation: null as Invitation | null,
  hasErrors: false,
  accepted: false,
  rejected: false,
};

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    resetInvitationsState: (
      state,
      {
        payload,
      }: PayloadAction<{
        resetInvitation?: boolean;
      }>,
    ) => {
      state.hasErrors = false;
      state.loading = false;
      state.accepted = false;
      state.rejected = false;
      if (payload.resetInvitation) {
        state.invitation = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInvitation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInvitation.fulfilled, (state, { payload }) => {
      state.hasErrors = false;
      state.loading = false;
      state.invitation = payload;
    });
    builder.addCase(getInvitation.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
      navigate('/error', {
        state: {
          error: 'You do not have access to this invitation. Please try again.',
        },
      });
    });
    builder.addCase(acceptInvitation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(acceptInvitation.fulfilled, (state) => {
      state.hasErrors = false;
      state.loading = false;
      state.accepted = true;
    });
    builder.addCase(acceptInvitation.rejected, (state, { payload }) => {
      state.hasErrors = true;
      state.loading = false;
      const errorMessage = handleAcceptErrorMessaging(payload as number);
      navigate('/error', {
        state: {
          error: errorMessage,
        },
      });
    });
    builder.addCase(rejectInvitation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(rejectInvitation.fulfilled, (state) => {
      state.hasErrors = false;
      state.loading = false;
      state.rejected = true;
    });
    builder.addCase(rejectInvitation.rejected, (state, { payload }) => {
      state.hasErrors = true;
      state.loading = false;
      const errorMessage = handleRejectErrorMessaging(payload as number);
      navigate('/error', {
        state: {
          error: errorMessage,
        },
      });
    });
    builder.addCase(getSendClaimedComms.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
      state.rejected = true;
    });
  },
});

export const { resetInvitationsState } = invitationsSlice.actions;

export default invitationsSlice.reducer;
