import { createSlice } from '@reduxjs/toolkit';
import { fetchModel } from './actions';

export interface CommissionModelState {
  loading: boolean;
  success: boolean;
  errorMessage: string | undefined;
  model: CommissionModel | null;
}

const initialState: CommissionModelState = {
  success: false,
  loading: false,
  errorMessage: '',
  model: null,
};

const commissionModelSlice = createSlice({
  name: 'commissionModel',
  initialState,
  reducers: {
    setCommissionModel(state, { payload }) {
      state.model = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModel.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.model = payload;
      state.errorMessage = undefined;
    });
    builder.addCase(fetchModel.rejected, (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = payload as string;
    });
  },
});

export const { setCommissionModel } = commissionModelSlice.actions;

export default commissionModelSlice.reducer;
