/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { navigate } from 'gatsby-link';
import { fetchAgentById } from './actions';

const initialState = {
  hasErrors: false,
  loading: false,
  agent: null as Agent | null,
};

const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Async agent fetch
    builder.addCase(fetchAgentById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAgentById.fulfilled, (state, { payload }) => {
      state.agent = payload;
      state.hasErrors = false;
      state.loading = false;
    });
    builder.addCase(fetchAgentById.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching your information. Please try again or contact a Clever support member.',
        },
      });
    });
  },
});

// Reducer
export default agentsSlice.reducer;
