import parse from 'libphonenumber-js';

/**
 * Parses phone numbers into a standard US format
 *
 * @param phoneNumber - Phone number to parse
 * @returns - Parsed phone number or undefined if invalid
 */
const parsePhoneNumber = (phoneNumber: string): string | undefined => {
  if (typeof phoneNumber !== 'string' || phoneNumber.length < 7) {
    return undefined;
  }

  const parsed = parse(phoneNumber, 'US');

  return parsed?.formatNational() || undefined;
};

export default parsePhoneNumber;
