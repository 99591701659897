const format = (price: any): string | undefined => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format(Number(price));

/**
 * Price formatter. Rounds to the nearest dollar and adds a dollar sign.
 *
 * @param price Value to format. Returns the original value if not a number.
 * @returns Formatted price
 */
const formatPrice = (price: any): string | undefined => {
  if (!['string', 'number'].includes(typeof price)) {
    return undefined;
  }

  if (typeof price === 'string') {
    const strippedPrice = price.replace(/[^0-9.]/g, '');

    if (!Number(strippedPrice)) {
      return price;
    }

    return format(strippedPrice);
  }

  return format(price);
};

export default formatPrice;
