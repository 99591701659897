import { createBucketClient } from '@cosmicjs/sdk';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';

const bucket = createBucketClient({
  bucketSlug: process.env.COSMIC_SLUG || '',
  readKey: process.env.COSMIC_READ_KEY || '',
});

export const fetchAnnouncements = createAsyncThunk(
  'announcements/fetchAnnouncements',
  async (skip?: number) => {
    try {
      const { objects } = await bucket.objects
        .find({
          type: 'announcements',
        })
        .props(['slug', 'title', 'metadata', 'created_at'])
        .sort('-created_at')
        .limit(20)
        .skip(skip || 0)
        .status(
          ['development', 'staging'].includes(process.env.NODE_ENV || '')
            ? 'any'
            : 'published',
        );

      return { objects };
    } catch (error) {
      Sentry.captureException(error);
      return { error };
    }
  },
);
