/* eslint-disable no-param-reassign */
import { navigate } from 'gatsby';
import { createSlice } from '@reduxjs/toolkit';
import {
  ConnectionCounts,
  getConnectionsCounts,
  requestActiveConnections,
  requestClosedConnections,
  requestOnHoldConnections,
  requestPaymentConnections,
  requestReleasedConnections,
  requestSingleConnection,
  PaginatedConnections,
  requestRecentlyUpdatedConnections,
  getNextConnections,
  ConnectionTypes,
  requestPaymentsProcessedConnections,
  getConnectionsFromPageCount,
  filterConnections,
  fetchAllC2Cs,
  PaginatedInvitations,
} from './actions';
import { C2CTypes } from '../../Enums/C2CTypes';

const initialState = {
  hasErrors: false,
  loading: false,
  noLoading: true,
  connectionCountsLoading: false,
  paymentConnectionsLoading: false,
  paymentProcessedLoading: false,
  onHoldLoading: false,
  activeLoading: false,
  recentlyUpdatedLoading: false,
  closedLoading: false,
  releasedLoading: false,
  paymentConnections: null as PaginatedConnections | null,
  paymentsProcessedConnections: null as PaginatedConnections | null,
  onHoldConnections: null as PaginatedConnections | null,
  activeConnections: null as PaginatedConnections | null,
  recentlyUpdatedConnections: null as PaginatedConnections | null,
  releasedConnections: null as PaginatedConnections | null,
  closedConnections: null as PaginatedConnections | null,
  singleConnection: null as Connection | null,
  connectionCounts: null as ConnectionCounts | null,
  unclaimedInvitations: null as PaginatedInvitations | null,
  claimedInvitations: null as PaginatedInvitations | null,
  inactiveInvitations: null as PaginatedInvitations | null,
};

const concatNextPageResults = (
  oldConnections: PaginatedConnections | null,
  payload: PaginatedConnections,
): PaginatedConnections | null => {
  const newConnections = oldConnections;
  if (newConnections) {
    newConnections.results = newConnections.results
      .concat(payload.results)
      .flat();
    newConnections.next = payload.next;
    newConnections.count = payload.count;
    newConnections.previous = payload.previous;
  }
  return newConnections;
};

const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    setNoLoading: (state, { payload }) => {
      state.noLoading = payload;
    },
    manuallySetConnectionCounts: (state, { payload }) => {
      state.connectionCounts = { ...state, ...payload };
    },
  },
  extraReducers: (builder) => {
    // Payment Connections
    builder.addCase(requestPaymentConnections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      requestPaymentConnections.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.paymentConnections = payload;
      },
    );
    builder.addCase(requestPaymentConnections.rejected, (state) => {
      state.hasErrors = true;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching payment connections. Please try again.',
        },
      });
    });
    // Payments Processed Connections
    builder.addCase(requestPaymentsProcessedConnections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      requestPaymentsProcessedConnections.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.paymentsProcessedConnections = payload;
      },
    );
    builder.addCase(requestPaymentsProcessedConnections.rejected, (state) => {
      state.hasErrors = true;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching payment connections. Please try again.',
        },
      });
    });
    // On hold connections
    builder.addCase(requestOnHoldConnections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      requestOnHoldConnections.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.onHoldConnections = payload;
      },
    );
    builder.addCase(requestOnHoldConnections.rejected, (state) => {
      state.hasErrors = true;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching on hold connections. Please try again.',
        },
      });
    });
    // Active Recently Updated Connections
    builder.addCase(requestRecentlyUpdatedConnections.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      requestRecentlyUpdatedConnections.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.recentlyUpdatedConnections = payload;
      },
    );
    builder.addCase(requestRecentlyUpdatedConnections.rejected, (state) => {
      state.hasErrors = true;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching recently updated connections. Please try again.',
        },
      });
    });
    // Active Connections
    builder.addCase(requestActiveConnections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      requestActiveConnections.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.activeConnections = payload;
      },
    );
    builder.addCase(requestActiveConnections.rejected, (state) => {
      state.hasErrors = true;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching active connections. Please try again.',
        },
      });
    });
    // Released Connections
    builder.addCase(requestReleasedConnections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      requestReleasedConnections.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.releasedConnections = payload;
      },
    );
    builder.addCase(requestReleasedConnections.rejected, (state) => {
      state.hasErrors = true;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching released connections. Please try again.',
        },
      });
    });
    // Closed Connections
    builder.addCase(requestClosedConnections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      requestClosedConnections.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.closedConnections = payload;
      },
    );
    builder.addCase(requestClosedConnections.rejected, (state) => {
      state.hasErrors = true;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching closed connections. Please try again.',
        },
      });
    });
    // Single Connection
    builder.addCase(requestSingleConnection.pending, (state) => {
      state.loading = state.noLoading;
    });
    builder.addCase(requestSingleConnection.fulfilled, (state, { payload }) => {
      state.hasErrors = false;
      state.loading = false;
      state.singleConnection = payload;
    });
    builder.addCase(requestSingleConnection.rejected, (state) => {
      state.hasErrors = true;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching the single connection. Please try again.',
        },
      });
    });
    // Connection Counts
    builder.addCase(getConnectionsCounts.pending, (state) => {
      state.connectionCountsLoading = true;
    });
    builder.addCase(getConnectionsCounts.fulfilled, (state, { payload }) => {
      state.hasErrors = false;
      state.connectionCounts = payload;
      state.connectionCountsLoading = false;
    });
    builder.addCase(getConnectionsCounts.rejected, (state) => {
      state.hasErrors = true;
      state.connectionCountsLoading = false;
    });

    // // //
    // Pagination
    // // //

    builder.addCase(getConnectionsFromPageCount.pending, (state, { meta }) => {
      // state.loading = true;
      const { type } = meta.arg;
      switch (type) {
        case C2CTypes.claimed:
        case C2CTypes.inactive:
        case C2CTypes.unclaimed:
          state.loading = true;
          break;
        case ConnectionTypes.active: {
          state.activeLoading = true;
          break;
        }
        case ConnectionTypes.activeUpdated: {
          state.recentlyUpdatedLoading = true;
          break;
        }
        case ConnectionTypes.closed: {
          state.closedLoading = true;
          break;
        }
        case ConnectionTypes.onHold: {
          state.onHoldLoading = true;
          break;
        }
        case ConnectionTypes.released: {
          state.releasedLoading = true;
          break;
        }
        case ConnectionTypes.payments: {
          state.paymentConnectionsLoading = true;
          break;
        }
        case ConnectionTypes.paymentsProcessed: {
          state.paymentProcessedLoading = true;
          break;
        }
        default:
          break;
      }
    });

    builder.addCase(
      getConnectionsFromPageCount.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        switch (payload.type) {
          case C2CTypes.claimed:
            state.claimedInvitations = payload.data as PaginatedInvitations;
            state.loading = false;
            break;
          case C2CTypes.inactive:
            state.inactiveInvitations = payload.data as PaginatedInvitations;
            state.loading = false;
            break;
          case C2CTypes.unclaimed:
            state.unclaimedInvitations = payload.data as PaginatedInvitations;
            state.loading = false;
            break;
          case ConnectionTypes.active: {
            state.activeConnections = payload.data as PaginatedConnections;
            state.activeLoading = false;
            break;
          }
          case ConnectionTypes.activeUpdated: {
            state.recentlyUpdatedConnections = payload.data as PaginatedConnections;
            state.recentlyUpdatedLoading = false;
            break;
          }
          case ConnectionTypes.closed: {
            state.closedConnections = payload.data as PaginatedConnections;
            state.closedLoading = false;
            break;
          }
          case ConnectionTypes.onHold: {
            state.onHoldConnections = payload.data as PaginatedConnections;
            state.onHoldLoading = false;
            break;
          }
          case ConnectionTypes.released: {
            state.releasedConnections = payload.data as PaginatedConnections;
            state.releasedLoading = false;
            break;
          }
          case ConnectionTypes.payments: {
            state.paymentConnections = payload.data as PaginatedConnections;
            state.paymentConnectionsLoading = false;
            break;
          }
          case ConnectionTypes.paymentsProcessed: {
            state.paymentsProcessedConnections = payload.data as PaginatedConnections;
            state.paymentProcessedLoading = false;
            break;
          }
          default:
            break;
        }
        state.loading = false;
      },
    );

    builder.addCase(getConnectionsFromPageCount.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching recently updated connections. Please try again.',
        },
      });
    });

    builder.addCase(getNextConnections.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getNextConnections.fulfilled, (state, { payload }) => {
      state.hasErrors = false;
      state.loading = false;

      switch (payload.type) {
        case ConnectionTypes.active: {
          const newConnections = concatNextPageResults(
            state.activeConnections,
            payload.data as PaginatedConnections,
          );
          state.activeConnections = newConnections;
          break;
        }
        case ConnectionTypes.activeUpdated: {
          const newConnections = concatNextPageResults(
            state.recentlyUpdatedConnections,
            payload.data as PaginatedConnections,
          );
          state.recentlyUpdatedConnections = newConnections;
          break;
        }
        case ConnectionTypes.closed: {
          const newConnections = concatNextPageResults(
            state.closedConnections,
            payload.data as PaginatedConnections,
          );
          state.closedConnections = newConnections;
          break;
        }
        case ConnectionTypes.onHold: {
          const newConnections = concatNextPageResults(
            state.onHoldConnections,
            payload.data as PaginatedConnections,
          );
          state.onHoldConnections = newConnections;
          break;
        }
        case ConnectionTypes.released: {
          const newConnections = concatNextPageResults(
            state.releasedConnections,
            payload.data as PaginatedConnections,
          );
          state.releasedConnections = newConnections;
          break;
        }
        case ConnectionTypes.payments: {
          const newConnections = concatNextPageResults(
            state.paymentConnections,
            payload.data as PaginatedConnections,
          );
          state.paymentConnections = newConnections;
          break;
        }
        case ConnectionTypes.paymentsProcessed: {
          const newConnections = concatNextPageResults(
            state.paymentsProcessedConnections,
            payload.data as PaginatedConnections,
          );
          state.paymentsProcessedConnections = newConnections;
          break;
        }
        default:
          break;
      }
    });

    builder.addCase(getNextConnections.rejected, (state) => {
      state.hasErrors = true;
      navigate('/error', {
        state: {
          error:
            'There was an error fetching recently updated connections. Please try again.',
        },
      });
    });

    // FILTER CONNECTIONS
    builder.addCase(filterConnections.pending, (state, { meta }) => {
      // state.loading = true;
      const { type } = meta.arg;
      switch (type) {
        case ConnectionTypes.active: {
          state.activeLoading = true;
          break;
        }
        case ConnectionTypes.activeUpdated: {
          state.recentlyUpdatedLoading = true;
          break;
        }
        case ConnectionTypes.closed: {
          state.closedLoading = true;
          break;
        }
        case ConnectionTypes.onHold: {
          state.onHoldLoading = true;
          break;
        }
        case ConnectionTypes.released: {
          state.releasedLoading = true;
          break;
        }
        case ConnectionTypes.payments: {
          state.paymentConnectionsLoading = true;
          break;
        }
        case ConnectionTypes.paymentsProcessed: {
          state.paymentProcessedLoading = true;
          break;
        }
        default:
          break;
      }
    });

    builder.addCase(filterConnections.fulfilled, (state, { payload, meta }) => {
      state.hasErrors = false;
      switch (meta.arg.type) {
        case ConnectionTypes.active: {
          state.activeConnections = payload;
          state.activeLoading = false;
          break;
        }
        case ConnectionTypes.activeUpdated: {
          state.recentlyUpdatedConnections = payload;
          state.recentlyUpdatedLoading = false;
          break;
        }
        case ConnectionTypes.closed: {
          state.closedConnections = payload;
          state.closedLoading = false;
          break;
        }
        case ConnectionTypes.onHold: {
          state.onHoldConnections = payload;
          state.onHoldLoading = false;
          break;
        }
        case ConnectionTypes.released: {
          state.releasedConnections = payload;
          state.releasedLoading = false;
          break;
        }
        case ConnectionTypes.payments: {
          state.paymentConnections = payload;
          state.paymentConnectionsLoading = false;
          break;
        }
        case ConnectionTypes.paymentsProcessed: {
          state.paymentsProcessedConnections = payload;
          state.paymentProcessedLoading = false;
          break;
        }
        default:
          break;
      }
      state.loading = false;
    });

    builder.addCase(filterConnections.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
      navigate('/error', {
        state: {
          error: 'There was an error filtering connections. Please try again.',
        },
      });
    });
    // Invitations
    builder.addCase(fetchAllC2Cs.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAllC2Cs.fulfilled, (state, { payload, meta }) => {
      state.hasErrors = false;
      const { type } = meta.arg;
      switch (type) {
        case C2CTypes.unclaimed: {
          state.unclaimedInvitations = payload;
          break;
        }
        case C2CTypes.claimed: {
          state.claimedInvitations = payload;
          break;
        }
        case C2CTypes.inactive: {
          state.inactiveInvitations = payload;
          break;
        }
        default:
          break;
      }
      state.loading = false;
    });

    builder.addCase(fetchAllC2Cs.rejected, (state) => {
      state.hasErrors = true;
      state.loading = false;
      navigate('/error', {
        state: {
          error: 'There was an error fetching invitations. Please try again.',
        },
      });
    });
  },
});

export const { setNoLoading, manuallySetConnectionCounts } = connectionsSlice.actions;

export default connectionsSlice.reducer;
