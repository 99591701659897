import axios from 'axios';
import { addMinutes } from 'date-fns';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

export const RefreshState = {
  SuccessfullyRefreshedTokens: 'SuccessfullyRefreshedTokens',
  ErrorRefreshingTokens: 'ErrorRefreshingTokens',
};

const baseURL = process.env.GATSBY_API_URL || 'http://localhost:8000';

const refreshTokens = async () => {
  const refresh = Cookies.get('refresh');
  try {
    const response = await axios.post(`${baseURL}/contacts/token/refresh/`, {
      refresh,
    });
    const newAccess = response.data.access;
    const newRefresh = response.data.refresh;
    let decodedToken: { [key: string]: any };
    let decodedRefresh: { [key: string]: any };
    try {
      decodedToken = jwtDecode(newAccess);
      decodedRefresh = jwtDecode(newRefresh);
    } catch (e) {
      const currentDate = new Date();
      const datePlus5Min = addMinutes(currentDate, 5);
      Cookies.set('access', newAccess, {
        expires: datePlus5Min,
      });
      Cookies.set('refresh', newRefresh, {
        expires: 90,
      });
      return RefreshState.SuccessfullyRefreshedTokens;
    }

    Cookies.set('access', newAccess, {
      expires: new Date(decodedToken.exp * 1000),
    });
    Cookies.set('refresh', newRefresh, {
      expires: new Date(decodedRefresh.exp * 1000),
    });
    return RefreshState.SuccessfullyRefreshedTokens;
  } catch (e) {
    // Error occured within the refresh and could be a race condition
    // Try the refresh check once more
    return RefreshState.ErrorRefreshingTokens;
  }
};

export default refreshTokens;
