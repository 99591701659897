/**
 * Get reset password url
 *
 * @returns {string} Clever reset password gateway url
 */

const getPasswordResetUrl = (): string => {
  const env = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

  if (env === 'production') {
    return 'https://login.listwithclever.com/reset-password/email/';
  }

  if (env === 'staging') {
    return 'https://login-staging.listwithclever.com/reset-password/email/';
  }

  return 'http://localhost:3001/reset-password/email/';
};

export default getPasswordResetUrl;
