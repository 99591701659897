const getInvoiceType = (input: string | null) => {
  if (input === 'Sell') {
    return 'Listing';
  }
  if (input === 'Buy') {
    return 'Buyer';
  }
  return null;
};

export default getInvoiceType;
