import getDaysBetween from '../getDaysBetween/getDaysBetween';

/*
  Due 10 days after closing date
*/
const getPaymentDueStatus = (date: string | Date | null): string => {
  if (!date) {
    return '';
  }
  const daysBetween = getDaysBetween(date);
  if (daysBetween > 10) {
    // payment x days overdue
    const daysDue = daysBetween - 10;
    const daysText = daysDue === 1 ? 'day' : 'days';
    return `Payment ${daysDue} ${daysText} overdue`;
  }
  // payment due in x days
  const daysDue = 10 - daysBetween;
  const daysText = daysDue === 1 ? 'day' : 'days';
  return `Payment due in ${daysDue} ${daysText}`;
};

export default getPaymentDueStatus;
