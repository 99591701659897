import * as Sentry from '@sentry/gatsby';
import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../AxiosInstance';

// //
// Async Thunk Actions
// //
export const fetchSearchById = createAsyncThunk<Search, string | number>(
  'searches/fetchSearchById',
  async (searchId) => {
    try {
      const response = await instance.get(
        `/deals/search/${searchId}/?format=json`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
);
