/* eslint-disable camelcase */
/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/always-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/gatsby';
import { createNewMagicLink, getTokensFromMagicLink } from './actions';

interface MagicError {
  sent_by?: string[];
  magic_link?: string[];
  message?: string;
}

const initialState = {
  loading: false,
  hasErrors: false,
  success: false,
  sentNewLink: false,
  errorMessage: {} as MagicError,
};

const magicLinksSlice = createSlice({
  name: 'magicLinks',
  initialState,
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.hasErrors = false;
      state.success = false;
      state.sentNewLink = false;
      state.errorMessage = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTokensFromMagicLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTokensFromMagicLink.fulfilled, (state, { payload }) => {
      state.loading = false;

      if ((payload as any).error) {
        state.hasErrors = true;
        state.success = false;
      } else {
        state.hasErrors = false;
        state.success = true;
      }
    });
    builder.addCase(
      getTokensFromMagicLink.rejected,
      (state, { error, meta }) => {
        state.loading = false;
        state.hasErrors = true;
        if (
          error.message === '410'
          || error.message === '404'
          || error.message === '500'
        ) {
          if (error.message === '500') {
            // Track error in GA using the magic link uuid
            ReactGA.event({
              category: 'login',
              action: 'magic link failure',
              value: meta.arg as number,
            });
            Sentry.captureException({
              error: '500',
              message: 'Magic link failure',
              magic_uuid: meta.arg,
            });
            navigate('/refresh-magic-link', {
              state: {
                title: 'Something went wrong',
                error:
                  'There was an error on our end and we are working on it. Please reach out to Clever if you require immediate assistance.',
                status: '500',
              },
            });
            return;
          }
          Sentry.captureException({
            error: error.message,
            message: 'Magic link failure',
            magic_uuid: meta.arg,
          });
          navigate('/refresh-magic-link', {
            state: {
              title:
                error.message === '404'
                  ? 'Magic link is no longer valid'
                  : 'Magic link has expired',
              error: 'To continue please request a new invitation link.',
              url: `${window.location.href}`,
              magic_uuid: meta.arg,
              status: error.message,
            },
          });

          // Magic link has already been accessed or has expired.
          // Navigate to /refresh-magic-link
        }
      },
    );
    builder.addCase(createNewMagicLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewMagicLink.fulfilled, (state, { payload }) => {
      state.loading = false;

      if ((payload as any).error) {
        Sentry.captureException({
          error: '500',
          message: 'Magic link refresh failure',
        });
        state.errorMessage = {
          message:
            'Something went wrong on our end while creating a new link. If this is still an issue please contact Clever support.',
        };
        state.sentNewLink = false;
      } else {
        state.hasErrors = false;
        state.sentNewLink = true;
      }
    });
    builder.addCase(createNewMagicLink.rejected, (state, { payload, meta }) => {
      state.loading = false;

      Sentry.captureEvent({
        message: 'Magic link refresh failure',
        extra: {
          request_details: {
            request_payload: { ...meta.arg },
            function: 'createNewMagicLink.rejected -- Reducer',
            location: '/refresh-magic-link',
            request_path: '/contacts/magic-link/',
          },
        },
      });
      if (payload) {
        state.errorMessage = payload;
      } else {
        state.errorMessage = {
          message:
            'Something went wrong on our end while creating a new link. If this is still an issue please contact Clever support.',
        };
      }
      state.hasErrors = true;
    });
  },
});

export const { resetState } = magicLinksSlice.actions;

export default magicLinksSlice.reducer;
