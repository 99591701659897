/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ErrorResponse, updateDeal } from './actions';

const initialState = {
  error: null as string | object | null,
  loading: false,
  deal: null as Deal | null,
  success: false,
};

const dealSlice = createSlice({
  name: 'deal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateDeal.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(updateDeal.fulfilled, (state, { payload }) => {
      state.loading = false;
      if ((payload as ErrorResponse) && (payload as ErrorResponse).error) {
        state.error = (payload as ErrorResponse).message;
      } else {
        state.deal = payload as Deal;
        state.success = true;
      }
    });
    builder.addCase(updateDeal.rejected, (state, { error }) => {
      state.error = error.message as any;
    });
  },
});

export default dealSlice.reducer;
