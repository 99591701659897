const getCommissionModelName = (commissionModelName) => {
  switch (commissionModelName) {
    case 'Flat Fee Model':
      return 'Flat $3,000 Listing Fee';
    case '1% Model':
      return '1% Listing Fee';
    case '<125k Model':
      return 'Traditional 5-6% Commission';
    case '<75k Model':
      return 'Traditional Model';
    case 'Buyer Tiered Rebate Model':
    case 'Buyer No Clever Cash Back':
      return 'Traditional Buyer';
    case 'Buyer $1K Rebate Model':
    case 'Buyer 1% Rebate Model':
      return 'Old Rebate Model';
    case 'Clever Cash Back ($250)':
    case 'Clever Cash Back (0.5%)':
      return 'Clever Cash Back';
    // Price Test
    case 'Buyer Model (new $250)':
      return 'Clever Cash Back';
    case '1.5% Model':
      return '1.5% Listing Fee';
    case 'Mr Cooper Buyer CCB':
    case 'Mr Cooper Seller CCB':
      return 'Lender Partner Model';
    default:
      return '';
  }
};

export default getCommissionModelName;
