const willAutomaticallyCreateHold = (date: Date | null) => {
  const holdCreationCutoffDate = new Date();
  holdCreationCutoffDate.setDate(holdCreationCutoffDate.getDate() + 7);
  if (!date) {
    return false;
  }
  return date > holdCreationCutoffDate;
};

export default willAutomaticallyCreateHold;
